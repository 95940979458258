import { PublicKey, SYSVAR_RENT_PUBKEY, SystemProgram } from "@solana/web3.js";
import {
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
} from '@solana/spl-token'
import BaseInstruction from "./base";
import { BN } from '@coral-xyz/anchor'

const HOLDING_PREFIX = 'holding';

export default class CreateDonationInstruction extends BaseInstruction  {
  static findHoldingWalletAccount(programId, donationDataPubkey) {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from(HOLDING_PREFIX),
        donationDataPubkey.toBuffer(),
      ],
      programId,
    );
  }

  async getHoldingWallet(holdingWalletOwnerPubkey, donationMintPubKey, payerPubkey) {
    const holdingWalletPubkey = await getAssociatedTokenAddress(
      donationMintPubKey,
      holdingWalletOwnerPubkey,
      true,
    );

    const holdingWalletPubkeyInfo = await this.program.provider.connection.getAccountInfo(holdingWalletPubkey);
    let instruction = null;
    if (holdingWalletPubkeyInfo == null) {
      instruction = createAssociatedTokenAccountInstruction(
        payerPubkey,
        holdingWalletPubkey,
        holdingWalletOwnerPubkey,
        donationMintPubKey,
      )
    }

    return {
      pubkey: holdingWalletPubkey,
      instruction,
    }
  }

  async create(
    amount,
    ipfsHash,
    endingTimestamp,
    donationDataPubkey,
    donationProtocolDataPubkey,
    recipientTokenPubkey,
    creatorDataPubkey,
    donationMintPubKey,
    creatorWalletPubkey,
  ) {
    const [
      holdingWalletOwnerPubkey,
      holdingWalletOwnerBump,
    ] = CreateDonationInstruction.findHoldingWalletAccount(this.program.programId, donationDataPubkey)

    const holdingWallet = await this.getHoldingWallet(holdingWalletOwnerPubkey, donationMintPubKey, creatorWalletPubkey)
    const instructions = []
    if (holdingWallet.instruction) {
      instructions.push(holdingWallet.instruction)
    }
    instructions.push(this.program.instruction.createDonation(
      new BN(amount),
      ipfsHash,
      new BN(endingTimestamp),
      holdingWalletOwnerBump,
      {
        accounts: {
          donationData: donationDataPubkey,
          donationProtocol: donationProtocolDataPubkey,
          holdingWallet: holdingWallet.pubkey,
          holdingWalletOwner: holdingWalletOwnerPubkey,
          recipient: recipientTokenPubkey,
          creatorData: creatorDataPubkey,
          donationMint: donationMintPubKey,
          creatorWalletAddress: creatorWalletPubkey,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
      }
    ))

    return instructions
  }
}
