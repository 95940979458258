import { BN } from '@coral-xyz/anchor'

// amountString = 123123.12321
function parseAmount(amountString, decimals) {
  const decimalsBn = new BN(10).pow(new BN(decimals));
  const [whole, fractional] = amountString.split('.');
  const wholeBn = new BN(whole);
  let amountBn = decimalsBn.mul(wholeBn);

  if (fractional) {
    const fractionalAmountBn = new BN(fractional.slice(0, decimals).padEnd(decimals, '0'));
    amountBn = amountBn.add(fractionalAmountBn);
  }
  
  return amountBn;
}

function formattedAmount(amount, decimals) {
  const amountStr = amountWithDecimalPoint(amount, decimals)
  const formattedAmount = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: decimals,
  }).format(amountStr);

  return formattedAmount
}

function amountWithDecimalPoint(amount, decimals) {
  const amountString = amount.toString();
  
  const integerPart = amountString.slice(0, -decimals);
  const decimalPart = amountString.slice(-decimals).padStart(decimals, '0');
  const amountNumber = integerPart + '.' + decimalPart;
  
  return amountNumber;
}

export {
  parseAmount,
  formattedAmount,
  amountWithDecimalPoint,
}
