import {
  getAssociatedTokenAddress,
} from '@solana/spl-token'

async function getAssociatedTokenAccount(connection, mintAddr, owner, allowOwnerOffCurve = false) {
  const accountPubkey = await getAssociatedTokenAddress(
    mintAddr,
    owner,
    allowOwnerOffCurve,
  );

  const accountInfo =  await connection.getAccountInfo(accountPubkey);
  
  return {
    accountPubkey,
    accountInfo,
  }
}

export {
  getAssociatedTokenAccount,
}
