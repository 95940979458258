import { Controller } from "@hotwired/stimulus"
import { Connection, PublicKey } from "@solana/web3.js"
import SolanaDonationDataViewer from "./tx/solana_donation_data_viewer"
import { formattedAmount } from "./utils/amount"
import { addressLink, trimAddress } from "./utils/wallet"
import { timestampToUTC } from "./utils/dates_helper"

export default class extends Controller {
  static targets = [ 
    'amountCollected',
    'amountDefined',
    'progressBar',
    'endDate',
    'creatorDataAddress',
    'recipientAddress',
    'progressPercents',
    'totalFundraisers',
    'activeFundraisers',
    'totalAmountRaised',
    'totalAmountInProgress',
    'donateBtns',
    'message',
  ]

  static values = {
    donationProtocolDataAddress: String,
    donationDataAddress: String,
    donationProgramId: String,
    networkUrl: String,
    donationTokenDecimals: Number,
    explorerDomain: String,
  }

  connect() {
    this.donationProtocolDataPubkey = new PublicKey(this.donationProtocolDataAddressValue)
    this.donationProgramIdPubkey = new PublicKey(this.donationProgramIdValue)
    this.donationDataPubkey = new PublicKey(this.donationDataAddressValue)
    this.updateDonationData()
  }
  
  async updateDonationData(event) {
    const connection = new Connection(this.networkUrlValue, 'confirmed')
    const program = SolanaDonationDataViewer.getProgram(this.donationProgramIdPubkey, connection)

    const donationData = await SolanaDonationDataViewer.checkAndGetDonationData(
      program,
      this.donationDataPubkey,
    )
    
    if (donationData.isClosed) {
      this.messageTarget.classList.remove('invisible')
      this.donateBtnsTarget.classList.add('invisible')
    } else {
      this.messageTarget.classList.add('invisible')
      this.donateBtnsTarget.classList.remove('invisible')
    }

    this.amountDefinedTarget.textContent = formattedAmount(
      donationData.amountCollecting,
      this.donationTokenDecimalsValue,
    )
    this.amountDefinedTarget.textContent = `${this.amountDefinedTarget.textContent} USDC`
    this.amountCollectedTarget.textContent = formattedAmount(
      donationData.totalAmountReceived,
      this.donationTokenDecimalsValue,
    )
    this.amountCollectedTarget.textContent = `${this.amountCollectedTarget.textContent} USDC`

    this.endDateTarget.textContent = timestampToUTC(donationData.endingTimestamp.toNumber())
    const progress = this.calcPercentage(
      donationData.totalAmountReceived,
      donationData.amountCollecting,
    )
    this.progressBarTarget.style.width = `${Math.min(progress, 100)}%`
    this.progressPercentsTarget.textContent = `${progress.toFixed(2)}%`
    this.creatorDataAddressTarget.textContent = trimAddress(donationData.creatorData.toString())
    this.creatorDataAddressTarget.href = addressLink(donationData.creatorData.toString(), this.explorerDomainValue)
    this.recipientAddressTarget.textContent = trimAddress(donationData.recipient.toString())
    this.recipientAddressTarget.href = addressLink(donationData.recipient.toString(), this.explorerDomainValue)

    const creatorData = await SolanaDonationDataViewer.checkAndGetCreatorData(
      program,
      donationData.creatorData,
    )
    this.totalFundraisersTarget.textContent = creatorData.donationsCreatedCount.toString()
    this.activeFundraisersTarget.textContent = creatorData.donationsCreatedCount.sub(
      creatorData.donationsCreatedCount
    ).toString()
    this.totalAmountRaisedTarget.textContent = formattedAmount(
      creatorData.totalAmountReceived.toString(),
      this.donationTokenDecimalsValue,
    )
    this.totalAmountInProgressTarget.textContent = formattedAmount(
      creatorData.totalAmountCollecting.toString(),
      this.donationTokenDecimalsValue,
    )
  }

  calcPercentage(amount, total) {
    return amount.muln(10000).div(total).toNumber() / 100
  }
}