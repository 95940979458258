import { Controller } from "@hotwired/stimulus"
import {
  Connection,
  PublicKey
} from '@solana/web3.js'
import SolanaDonation from "./tx/solana_donation_tx";
import { performTransaction } from './tx/perform_transaction'
import { DONATION_PROTO_ABI } from "./abi/donation_protocol_abi";
import { parseAmount } from "./utils/amount";
import { dateAfterDays, daysToMilliseconds } from "./utils/dates_helper";
import FlashMessage from './utils/flash_message'

export default class extends Controller {
  static targets = [
    'createDonationButton',
    'amount',
    'ipfsHash',
    'periodDays',
    'recipientAddress',
    'message',
    'spinner',
    'buttonText',
  ]

  static values = {
    donationProtocolDataAddress: String,
    donationProgramId: String,
    networkUrl: String,
    decimals: Number,
  }

  disableTargets(flag = true) {
    this.element.querySelectorAll('input, button, textarea').forEach((element) => {
      element.disabled = flag
    })
  }
  
  startProcessing() {
    this.disableTargets(true)
    this.spinnerTarget.hidden = false
    this.buttonTextTarget.innerText = 'Creating a fundraiser...'
  }

  endProcessing() {
    this.disableTargets(false)
    this.spinnerTarget.hidden = true
    this.buttonTextTarget.innerText = 'Create a fundraiser'
  }

  async submitForm(event) {
    event.preventDefault()
    const form = event.currentTarget
    const url = form.action
    const method = form.method
    const formData = new FormData(form);
    this.startProcessing()

    try {
      const ipfsHash = 'QmZv1z1Q2J' // hardcoded while not implemented
      const dateEnds = dateAfterDays(this.periodDaysTarget.value)
      const endingTimestamp = Math.floor(dateEnds.getTime() / 1000);
      
      const {
        isSuccessfull,
        error,
        signature,
      } = await this.createDonation(ipfsHash, endingTimestamp)
      
      if (!isSuccessfull) {
        console.error(error);
        this.endProcessing()
        return
      }

      formData.set('donation[date_end]', dateEnds.toString());
      formData.set('donation[onchain_address]', this.donationAddress?.toString());
      formData.set('donation[tx_hash]', signature);
      formData.set('donation[creator_address]', window?.solana?.publicKey.toString());
      const response = await fetch(url, {
        method,
        body: formData,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Accept': 'application/json'
        }
      })

      const data = await response.json()

      if (response.ok) {
        this.messageTarget.textContent += data.message
        this.messageTarget.classList.remove('hidden')

        window.location.href = `/donations/${data.id}/show_result`
      } else {
        // TODO: show error message
        console.error(data)
      }
    } catch (error) {
      // TODO: show error message
      console.error('Error:', error)
      await FlashMessage.trigger({ type: 'error', message: error.message })
    }

    this.endProcessing()

    // Continue with default event
    return true;
  }

  async createDonation(ipfsHash, endingTimestamp) {
    const recipientAddress = this.recipientAddressTarget.value
    const amount = parseAmount(this.amountTarget.value, this.decimalsValue)

    const provider = window.solana
    if (provider == null) {
      throw new Error('Phantom wallet has not been connected.')
    }
    const connection = new Connection(this.networkUrlValue, 'confirmed')
    const solanaDonation = new SolanaDonation(
      provider,
      connection,
      new PublicKey(this.donationProgramIdValue),
      new PublicKey(this.donationProtocolDataAddressValue),
    )

    const {
      transaction,
      signers,
    } = await solanaDonation.createDonation(
      amount,
      endingTimestamp,
      ipfsHash,
      new PublicKey(recipientAddress),
      new PublicKey(window.solana.publicKey)
    )
    this.donationAddress = signers[0].publicKey
    
    return await performTransaction(
      connection,
      provider,
      transaction,
      DONATION_PROTO_ABI.errors,
      signers,
    )
  }
}