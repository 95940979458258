import { Controller } from "@hotwired/stimulus"
import {
  Connection,
  PublicKey
} from '@solana/web3.js'
import SolanaDonation from "./tx/solana_donation_tx";
import { performTransaction } from './tx/perform_transaction'
import { DONATION_PROTO_ABI } from "./abi/donation_protocol_abi";
import { BN } from '@coral-xyz/anchor'
import { formattedAmount, parseAmount } from "./utils/amount";
import { transactionLink } from "./utils/wallet";
import SolanaDonationDataViewer from "./tx/solana_donation_data_viewer";
import { getAssociatedTokenAccount } from "./tx/spl_helper";

export default class extends Controller {
  static targets = [
    'donateButton',
    'amount',
    'txHash',
    'donationAddress',
    'buttonGroup',
    'donateModal',
    'spinner',
    'connectWalletMessage',
    'donateSuccessfulModal',
    'txLink',
    'successAmount',
    'closeFundraiserButton',
    'successDonateMessage',
    'successWithdrawMessage',
    'successWithdrawAmount',
  ]

  static values = {
    donationProtocolDataAddress: String,
    donationProgramId: String,
    networkUrl: String,
    decimals: Number,
    explorerDomain: String,
  }

  async initialize() {
    this.decimals = this.decimalsValue
    this.donationProgramIdPubkey = new PublicKey(this.donationProgramIdValue)
    this.donationProtocolDataPubkey = new PublicKey(this.donationProtocolDataAddressValue)
    this.donationDataPubkey = new PublicKey(this.donationAddressTarget.value)
  } 

  disableDonateButton(flag = true) {
    this.element.querySelectorAll('.btn-donate').forEach((element) => {
      element.disabled = flag
    })
  }

  async onWalletConnected() {
    await this.toggleWithdrawButton()
    this.connectWalletMessageTarget.classList.add('invisible');
    this.disableDonateButton(false)
  }

  onWalletDisconnected() {
    this.connectWalletMessageTarget.classList.remove('invisible');
    this.disableDonateButton(true)
  }

  async toggleWithdrawButton() {
    const connection = new Connection(this.networkUrlValue, 'confirmed')
    const program = SolanaDonationDataViewer.getProgram(this.donationProgramIdPubkey, connection)
    const donationProtocolData = await SolanaDonationDataViewer.checkAndGetDonationProtocolData(
      program,
      this.donationProtocolDataPubkey,
    )
    const donationData = await SolanaDonationDataViewer.checkAndGetDonationData(
      program,
      this.donationDataPubkey,
    )

    const {
      accountPubkey: recipientTokenAccountPubkey,
    } = await getAssociatedTokenAccount(
      connection,
      donationProtocolData.donationMint,
      window.solana.publicKey,
    )

    if (donationData.recipient.equals(recipientTokenAccountPubkey)
      && !donationData.isClosed
      && (donationData.endingTimestamp <= Date.now() / 1000 
        || donationData.totalAmountReceived.gte(donationData.amountCollecting))) {
      this.closeFundraiserButtonTarget.classList.remove('invisible')
    } else { 
      this.closeFundraiserButtonTarget.classList.add('invisible')
    }
  }

  disableTargets(flag = true) {
    this.element.querySelectorAll('input, button, textarea').forEach((element) => {
      element.disabled = flag
    })
    if (flag) {
      this.element.querySelectorAll('.input-field').forEach((element) => {
        element.classList.add('input-field-disabled')
      })
    } else {
      this.element.querySelectorAll('.input-field').forEach((element) => {
        element.classList.remove('input-field-disabled')
      })
    }
  }

  updateTargets(disabled = true) {
    this.disableTargets(disabled)
    this.spinnerTarget.hidden = !disabled
  }

  async submitForm(event) {
    event.preventDefault()
    const form = event.currentTarget
    const url = form.action
    const method = form.method
    const formData = new FormData(form);
    this.updateTargets(true)

    const amount = parseAmount(this.amountTarget.value, this.decimals)

    try {
      const {
        isSuccessfull,
        error,
        signature,
      } = await this.donate(this.donationDataPubkey, amount)
      
      if (!isSuccessfull) {
        // TODO: Show error message
        console.error(error);
        this.updateTargets(false)
        return
      }

      formData.set('donation_transaction[tx_hash]', signature);
      const response = await fetch(url, {
        method: method,
        body: formData,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Accept': 'application/json'
        }
      })

      const data = await response.json()

      if (response.ok) {
        this.processDonateTransactionResult(true, signature)
        form.reset()
      } else {
        // TODO: show error message
        console.error(data)
      }
    } catch (error) {
      // TODO: show error message
      console.error('Error:', error)
    }

    this.updateTargets(false)
  }

  async donate(donationAddress, amount) {
    const provider = window.solana
    if (provider == null) {
      throw new Error('Phantom wallet has not been connected.')
    }

    const connection = new Connection(this.networkUrlValue, 'confirmed')
    const solanaDonation = new SolanaDonation(
      provider,
      connection,
      this.donationProgramIdPubkey,
      this.donationProtocolDataPubkey,
    )
    
    const {
      transaction,
      signers,
    } = await solanaDonation.donate(
      amount,
      donationAddress,
      new PublicKey(window.solana.publicKey)
    )
    
    return await performTransaction(
      connection,
      provider,
      transaction,
      DONATION_PROTO_ABI.errors,
      signers,
    )
  }

  async closeFundraise(event) {
    event.preventDefault()
    this.updateTargets(true)

    try {
      const {
        isSuccessfull,
        error,
        signature,
      } = await this.withdrawFunds(this.donationDataPubkey)

      if (!isSuccessfull) {
        // TODO: Show error message
        console.error(error);
        this.updateTargets(false)
        return
      }

      this.processWithdrawTransactionResult(true, signature)
    } catch (error) {
      // TODO: show error message
      console.error('Error:', error)
    }
    this.updateTargets(false)
  }

  async withdrawFunds(donationAddress) {
    const provider = window.solana
    if (provider == null) {
      throw new Error('Phantom wallet has not been connected.')
    }

    const donationDataPubkey = new PublicKey(donationAddress)
    const connection = new Connection(this.networkUrlValue, 'confirmed')
    const solanaDonation = new SolanaDonation(
      provider,
      connection,
      this.donationProgramIdPubkey,
      this.donationProtocolDataPubkey,
    )

    const {
      transaction,
      signers,
    } = await solanaDonation.withdrawFunds(
      new PublicKey(window.solana.publicKey),
      donationDataPubkey,
    )

    const program = solanaDonation.program
    const donationData = await SolanaDonationDataViewer.getDonationData(program, donationDataPubkey)
    this.withdrawnAmount = formattedAmount(
      donationData.totalAmountReceived,
      this.decimals,
    )

    return await performTransaction(
      connection,
      provider,
      transaction,
      DONATION_PROTO_ABI.errors,
      signers,
    )
  }

  showButtonGroup() {
    this.buttonGroupTarget.classList.remove('hidden')
    this.donateButtonTarget.classList.add('hidden')
  }

  cancel() {
    this.buttonGroupTarget.classList.add('hidden')
    this.donateButtonTarget.classList.remove('hidden')
  }

  donateDefault10() {
    const amount = 10
    this.showModal(amount)
  }

  donateDefault25() {
    const amount = 25
    this.showModal(amount)
  }

  customDonate() {
    this.donateModalTarget.classList.remove('hidden')
  }

  showModal(amount) {
    this.amount = (new BN(amount)).muln(10 ** this.decimals).toString()
    this.amountTarget.value = amount
    this.donateModalTarget.classList.remove('hidden')
  }

  hideModal(event) {
    event.currentTarget.closest('.modal').classList.add('hidden')
  }

  processDonateTransactionResult(isTransactionOk, txSignature) {
    this.donateModalTarget.classList.add('hidden')
    if (isTransactionOk) {
      this.successAmountTarget.innerHTML = this.amountTarget.value
      this.updateTransactionLink(txSignature)
      this.donateSuccessfulModalTarget.classList.remove('hidden')
      window.dispatchEvent(new Event('donation:update'))
    } else {
      // show flash error message
    }
  }

  async processWithdrawTransactionResult(isTransactionOk, txSignature) {
    this.successDonateMessage.classList.add('hidden')
    this.successWithdrawMessage.classList.remove('hidden')
    if (isTransactionOk) {
      this.successWithdrawAmount.innerHTML = this.withdrawnAmount
      this.updateTransactionLink(txSignature)
      this.donateSuccessfulModalTarget.classList.remove('hidden')
      window.dispatchEvent(new Event('donation:update'))
    } else {
      // show flash error message
    }
  }

  updateTransactionLink(signature) {
    this.txLinkTarget.innerHTML = `${signature.substring(0, 30)}...`
    this.txLinkTarget.href = transactionLink(signature, this.explorerDomainValue)
  }
}
