const DONATION_PROTO_ABI = {
  version: "0.1.0",
  name: "donaproto",
  instructions: [
    {
      name: "initializeDonationProtocol",
      accounts: [
        {
          name: "donationProtocolData",
          isMut: true,
          isSigner: true
        },
        {
          name: "treasury",
          isMut: false,
          isSigner: false
        },
        {
          name: "treasuryOwner",
          isMut: false,
          isSigner: false
        },
        {
          name: "treasuryMint",
          isMut: false,
          isSigner: false
        },
        {
          name: "donationMint",
          isMut: false,
          isSigner: false
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "minAmountToEarn",
          type: "u64"
        },
        {
          name: "minAmountToCollect",
          type: "u64"
        },
        {
          name: "treasuryOwnerBump",
          type: "u8"
        }
      ]
    },
    {
      name: "initializeCreator",
      accounts: [
        {
          name: "creatorData",
          isMut: true,
          isSigner: false
        },
        {
          name: "donationProtocol",
          isMut: false,
          isSigner: false
        },
        {
          name: "creatorWalletAddress",
          isMut: false,
          isSigner: false
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false
        }
      ],
      args: []
    },
    {
      name: "initializeContributor",
      accounts: [
        {
          name: "contributorData",
          isMut: true,
          isSigner: false
        },
        {
          name: "donationProtocol",
          isMut: false,
          isSigner: false
        },
        {
          name: "contributorWalletAddress",
          isMut: false,
          isSigner: false
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "bump",
          type: "u8"
        }
      ]
    },
    {
      name: "createDonation",
      accounts: [
        {
          name: "donationData",
          isMut: true,
          isSigner: true
        },
        {
          name: "donationProtocol",
          isMut: false,
          isSigner: false
        },
        {
          name: "holdingWallet",
          isMut: false,
          isSigner: false
        },
        {
          name: "holdingWalletOwner",
          isMut: false,
          isSigner: false
        },
        {
          name: "recipient",
          isMut: false,
          isSigner: false
        },
        {
          name: "creatorData",
          isMut: true,
          isSigner: false
        },
        {
          name: "donationMint",
          isMut: false,
          isSigner: false
        },
        {
          name: "creatorWalletAddress",
          isMut: true,
          isSigner: true
        },
        {
          name: "systemProgram",
          isMut: false,
          isSigner: false
        },
        {
          name: "rent",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "amount",
          type: "u64"
        },
        {
          name: "ipfsHash",
          type: "string"
        },
        {
          name: "endingTimestamp",
          type: "u64"
        },
        {
          name: "holdingBump",
          type: "u8"
        }
      ]
    },
    {
      name: "donate",
      accounts: [
        {
          name: "donationData",
          isMut: true,
          isSigner: false
        },
        {
          name: "contributorData",
          isMut: true,
          isSigner: false
        },
        {
          name: "donationProtocol",
          isMut: false,
          isSigner: false
        },
        {
          name: "userTokenWallet",
          isMut: true,
          isSigner: false
        },
        {
          name: "userRewardTokenWallet",
          isMut: true,
          isSigner: false
        },
        {
          name: "rewardTreasury",
          isMut: true,
          isSigner: false
        },
        {
          name: "rewardTreasuryOwner",
          isMut: false,
          isSigner: false
        },
        {
          name: "holdingWallet",
          isMut: true,
          isSigner: false
        },
        {
          name: "donationMint",
          isMut: false,
          isSigner: false
        },
        {
          name: "rewardMint",
          isMut: false,
          isSigner: false
        },
        {
          name: "userWallet",
          isMut: true,
          isSigner: true
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: [
        {
          name: "amount",
          type: "u64"
        }
      ]
    },
    {
      name: "withdrawFunds",
      accounts: [
        {
          name: "donationData",
          isMut: true,
          isSigner: false
        },
        {
          name: "creatorData",
          isMut: true,
          isSigner: false
        },
        {
          name: "donationProtocol",
          isMut: false,
          isSigner: false
        },
        {
          name: "holdingWallet",
          isMut: true,
          isSigner: false
        },
        {
          name: "holdingWalletOwner",
          isMut: false,
          isSigner: false
        },
        {
          name: "recipientTokenWallet",
          isMut: true,
          isSigner: false
        },
        {
          name: "donationMint",
          isMut: false,
          isSigner: false
        },
        {
          name: "payer",
          isMut: true,
          isSigner: true
        },
        {
          name: "tokenProgram",
          isMut: false,
          isSigner: false
        }
      ],
      args: []
    }
  ],
  accounts: [
    {
      name: "DonationData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "amountCollecting",
            type: "u64"
          },
          {
            name: "totalAmountReceived",
            type: "u64"
          },
          {
            name: "endingTimestamp",
            type: "u64"
          },
          {
            name: "isClosed",
            type: "bool"
          },
          {
            name: "recipient",
            type: "publicKey"
          },
          {
            name: "donationProtocol",
            type: "publicKey"
          },
          {
            name: "holdingWallet",
            type: "publicKey"
          },
          {
            name: "creatorData",
            type: "publicKey"
          },
          {
            name: "holdingBump",
            type: "u8"
          },
          {
            name: "ipfsHash",
            type: "string"
          }
        ]
      }
    },
    {
      name: "ContributorData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "totalAmountDonated",
            type: "u64"
          },
          {
            name: "totalAmountEarned",
            type: "u64"
          },
          {
            name: "donationsCount",
            type: "u64"
          },
          {
            name: "donationProtocol",
            type: "publicKey"
          },
          {
            name: "bump",
            type: "u8"
          }
        ]
      }
    },
    {
      name: "CreatorData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "totalAmountReceived",
            type: "u64"
          },
          {
            name: "totalAmountCollecting",
            type: "u64"
          },
          {
            name: "donationsCreatedCount",
            type: "u64"
          },
          {
            name: "donationsClosedCount",
            type: "u64"
          },
          {
            name: "donationProtocol",
            type: "publicKey"
          }
        ]
      }
    },
    {
      name: "DonationProtocolData",
      type: {
        kind: "struct",
        fields: [
          {
            name: "treasuryMint",
            type: "publicKey"
          },
          {
            name: "treasury",
            type: "publicKey"
          },
          {
            name: "donationMint",
            type: "publicKey"
          },
          {
            name: "minAmountToEarn",
            type: "u64"
          },
          {
            name: "minAmountToCollect",
            type: "u64"
          },
          {
            name: "treasuryOwnerBump",
            type: "u8"
          }
        ]
      }
    }
  ],
  errors: [
    {
      code: 6000,
      name: "InvalidEndingTimestamp",
      msg: "Invalid ending timestamp"
    },
    {
      code: 6001,
      name: "InvalidBump",
      msg: "Invalid bump"
    },
    {
      code: 6002,
      name: "DonationClosed",
      msg: "Donation is closed"
    },
    {
      code: 6003,
      name: "DonationAmountZero",
      msg: "Donation amount can not be zero"
    },
    {
      code: 6004,
      name: "DonationEndingReqiuirementsNotMet",
      msg: "Donation ending requirements not met"
    },
    {
      code: 6005,
      name: "IpfsHashTooLong",
      msg: "Ipfs hash too long"
    },
    {
      code: 6006,
      name: "DonationAmountTooLow",
      msg: "Donation amount too low"
    }
  ],
  metadata: {
    address: "HbNNG85aBuR9W5F8YobTeDRRmXWFbDhLDS6WbLzWbLhH"
  }
}

export { DONATION_PROTO_ABI }
