const FlashMessage = {
  async trigger({type, message}) {
    try {
      const response = await fetch('/trigger_flash_message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ type, message })
      })

      if (!response.ok) {
        console.error('Failed to trigger flash message:', response.status, response.statusText)
        return
      }

      const html = await response.text()
      Turbo.renderStreamMessage(html)

    } catch (error) {
      console.error('Error setting flash message:', error)
    }
  }
}

export default FlashMessage
