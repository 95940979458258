import { PublicKey } from "@solana/web3.js";
import { DONATION_PROTO_ABI } from "../abi/donation_protocol_abi";
import { Program, AnchorProvider } from '@coral-xyz/anchor'
const TREASURY_PREFIX = 'treasury';

export default class SolanaDonationDataViewer {
  static getProgram(programId, connection) {
    return new Program(
      DONATION_PROTO_ABI,
      programId,
      new AnchorProvider(
        connection,
        {},
        'confirmed'
      )
    )
  }

  static async getDonationData(program, donationDataPubkey) {
    try {
      return await program.account.donationData.fetch(donationDataPubkey)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  static async checkAndGetDonationData(program, donationDataPubkey) {
    const donationData = await SolanaDonationDataViewer.getDonationData(program, donationDataPubkey)
    if (donationData == null) {
      throw new Error('Donation Data not found')
    }
    return donationData
  }

  static async getDonationProtocolData(program, donationProtocolDataPubkey) {
    try {
      return await program.account.donationProtocolData.fetch(donationProtocolDataPubkey)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  static async checkAndGetDonationProtocolData(program, donationProtocolDataPubkey) {
    const donationProtocolData = await SolanaDonationDataViewer.getDonationProtocolData(program, donationProtocolDataPubkey)
    if (donationProtocolData == null) {
      throw new Error('Donation Protocol Data not found')
    }
    return donationProtocolData
  }

  static async getCreatorData(program, creatorDataPubkey) {
    try {
      return await program.account.creatorData.fetch(creatorDataPubkey)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  static async checkAndGetCreatorData(program, creatorDataPubkey) {
    const creatorData = await SolanaDonationDataViewer.getCreatorData(program, creatorDataPubkey)
    if (creatorData == null) {
      throw new Error('Creator Data not found')
    }
    return creatorData
  }

  static async getContributorData(program, contributorDataPubkey) {
    try {
      return await program.account.contributorData.fetch(contributorDataPubkey)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  static findTreasuryAddress(programId, donationProtocolDataPubkey) {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from(TREASURY_PREFIX),
        donationProtocolDataPubkey.toBuffer(),
      ],
      programId,
    );
  }
}