import { Controller } from "@hotwired/stimulus"
import { Connection, PublicKey } from "@solana/web3.js"
import SolanaDonationDataViewer from "./tx/solana_donation_data_viewer"
import { trimAddress } from "./utils/wallet"
import { timestampToUTC } from "./utils/dates_helper"

export default class extends Controller {
  static targets = ['author', 'daysLeft', 'percents']

  static values = {
    donationProtocolDataAddress: String,
    donationProgramId: String,
    donationDataAddress: String,
    networkUrl: String,
  }

  connect() {
    this.donationProtocolDataPubkey = new PublicKey(this.donationProtocolDataAddressValue)
    this.donationProgramIdPubkey = new PublicKey(this.donationProgramIdValue)
    this.donationDataPubkey = new PublicKey(this.donationDataAddressValue)
    this.update()
  }

  async update() {
    const connection = new Connection(this.networkUrlValue, 'confirmed')
    const program = SolanaDonationDataViewer.getProgram(
      this.donationProgramIdPubkey,
      connection,
    )

    const donationData = await SolanaDonationDataViewer.checkAndGetDonationData(
      program,
      this.donationDataPubkey,
    )

    const progress = donationData.totalAmountReceived.muln(10000).div(donationData.amountCollecting).toNumber() / 100
    this.percentsTarget.textContent = `${progress.toFixed(2)}%`

    this.authorTarget.textContent = `By ${trimAddress(donationData.creatorData.toString(), 7, 8)}`

    const now = new Date()
    const daysLeft = Math.ceil((donationData.endingTimestamp.toNumber() - Math.floor(now.getTime() / 1000)) / 86400)
   
    if (daysLeft >= 0) {
      this.daysLeftTarget.textContent = `${daysLeft} days left`
    } else {
      this.daysLeftTarget.textContent = `Ended at ${timestampToUTC(donationData.endingTimestamp.toNumber())}`
    }
  }
}
