import BaseInstruction from "./base";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

export default class WithdrawFundsInstruction extends BaseInstruction {
  async create(
    donationProtocolDataPubkey,
    donationDataPubkey,
    creatorDataPubkey,
    holdingWalletOwnerPubkey,
    donationHoldingWalletPubkey,
    recipientTokenWalletPubkey,
    donationMintPubKey,
    creatorWalletPubkey,
  ) {
    return this.program.instruction.withdrawFunds(
      {
        accounts: {
          donationData: donationDataPubkey,
          creatorData: creatorDataPubkey,
          donationProtocol: donationProtocolDataPubkey,
          holdingWallet: donationHoldingWalletPubkey,
          holdingWalletOwner: holdingWalletOwnerPubkey,
          recipientTokenWallet: recipientTokenWalletPubkey,
          donationMint: donationMintPubKey,
          payer: creatorWalletPubkey,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      },
    )
  }
}
