import { Controller } from '@hotwired/stimulus'

const IMAGE_MB_MAX_SIZE = 2
const IMAGE_B_MAX_SIZE = Math.pow(1024, 2)
const ERROR_MESSAGE = `Image size must be less than ${IMAGE_MB_MAX_SIZE} megabytes`


export default class extends Controller {
  static targets = ['preview', 'input', 'error']

  verifyAndUpdate(event) {
    const input = this.inputTarget
    if (input.files && input.files[0]) {
      const file = input.files[0]
      const fileSizeMb = file.size / IMAGE_B_MAX_SIZE

      if (fileSizeMb > IMAGE_MB_MAX_SIZE) {
        this.errorTarget.innerText = ERROR_MESSAGE
        this.errorTarget.classList.remove('hidden')
      } else {
        this.errorTarget.classList.add('hidden')
        this.updateUrl(file)
      }
    }
  }

  updateUrl(file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      this.previewTarget.src = reader.result;
    };

    reader.readAsDataURL(file);
  }
}