function transactionLink(txHash, domain) {
  return `https://${domain}/tx/${txHash}?cluster=devnet`
}

function addressLink(address, domain) {
  return `https://${domain}/address/${address}?cluster=devnet`
}

function trimAddress(address, begin = 15, end = 15) {
  return `${address.slice(0, begin)}...${address.slice(-end)}`
}

export {
  transactionLink,
  addressLink,
  trimAddress,
}