import BaseInstruction from "./base";
import InitializeContributorInstruction from "./initialize_contributor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

export default class DonateInstruction extends BaseInstruction  {
  async create(
    amount,
    donationProtocolDataPubkey,
    contributorWalletPubkey,
    donationDataPubkey,
    contributorDonationTokenAccountPubkey,
    contributorRewardTokenAccountPubkey,
    rewardTreasuryOwnerPubkey,
    rewardTreasuryPubkey,
    donationHoldingWalletPubkey,
    donationMintPubKey,
    rewardMintPubkey,
  ) {
    const [contributorDataPubkey] = InitializeContributorInstruction.findContributorAddress(
      this.program.programId,
      donationProtocolDataPubkey,
      contributorWalletPubkey,
    )
    // TODO: rewardMint, donationMint can be fetched from donationProtocolData

    return this.program.instruction.donate(
      amount,
      {
        accounts: {
          donationData: donationDataPubkey,
          contributorData: contributorDataPubkey,
          donationProtocol: donationProtocolDataPubkey,
          userTokenWallet: contributorDonationTokenAccountPubkey,
          userRewardTokenWallet: contributorRewardTokenAccountPubkey,
          rewardTreasury: rewardTreasuryPubkey,
          rewardTreasuryOwner: rewardTreasuryOwnerPubkey,
          holdingWallet: donationHoldingWalletPubkey,
          donationMint: donationMintPubKey,
          rewardMint: rewardMintPubkey,
          userWallet: contributorWalletPubkey,
          tokenProgram: TOKEN_PROGRAM_ID,
        },
      },
    )
  }
}
