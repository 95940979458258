import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'source', 'icon' ]

  get content(){
    switch(this.sourceTarget.tagName.toLowerCase()) {
      case 'input':
      case 'textarea':
        return this.sourceTarget.value
      case 'a':
        return this.sourceTarget.href
      default:
        return this.sourceTarget.innerText
    }
  }

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.content).then(this.onCopy.bind(this))
  }

  onCopy() {
    if (!this.hasIconTarget) return

    setTimeout(this.stopAnimation.bind(this), 500)
    this.startAnimation()
  }

  startAnimation() {
    this.iconTarget.classList.add('animate-bounce')
  }

  stopAnimation() {
    this.iconTarget.classList.remove('animate-bounce')
  }
}