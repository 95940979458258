async function performTransaction(connection, signer, transaction, programAbiErrors = null, signers = null) {
  const isSuccessfull = false
  const { blockhash, feeCalculator } = await connection.getRecentBlockhash()
  transaction.feePayer = signer.publicKey 
  transaction.recentBlockhash = blockhash

  const balance = await connection.getBalance(signer.publicKey)
  if (balance < feeCalculator.lamportsPerSignature) {
    return {
      isSuccessfull, 
      error: 'Insufficient balance to pay transaction fee',
    }
  }

  if (signers != null) {
    transaction.partialSign(...signers)
  }

  let transactionSignature = null
  try {
    const signed = await signer.signTransaction(transaction, signers)

    transactionSignature = await connection.sendRawTransaction(
      signed.serialize(),
      { skipPreflight: true }
    )
    const confirmRes = await connection.confirmTransaction(transactionSignature)
    let programError = confirmRes.value.err
    if (programAbiErrors !== null && programError) {
      programError = formatError(programAbiErrors, confirmRes.value.err)
    }
    return {
      isSuccessfull: programError === null, 
      error: programError, 
      signature: transactionSignature,
    }
  } catch (e) {
    const result = await getSignatureResult(connection, transactionSignature, programAbiErrors)
    if (result) {
      // TODO: add consistent format in response with error message
      return result
    }

    return {
      isSuccessfull, 
      error: e.message || 'Unknown Error',
    }
  }
}

function formatError(errors, err) {
  if (err.InstructionError !== null && err.InstructionError.length === 2) {
    const errCode = err.InstructionError[1].Custom
    const matchingError = errors.find(error => error.code === errCode)
    if (matchingError) {
      return matchingError.msg
    }
    return 'Custom Error Code= ' + errCode
  }
  return 'Unknown Error'
}

async function getSignatureResult(connection, signature, programAbiErrors) {
  if (signature === null) {
    return null
  }

  try {
    const signatureStatus = await connection.getSignatureStatus(signature)
    let programError = signatureStatus.value.err
    if (programAbiErrors !== null && programError) {
      programError = formatError(programAbiErrors, signatureStatus.value.err)
    }
    return [signatureStatus.value.err === null, programError, signature]
  } catch (internalError) {
    console.error(internalError)
  }
  return null
}

export {
  performTransaction,
  formatError,
  getSignatureResult,
}