import { PublicKey, SYSVAR_RENT_PUBKEY, SystemProgram } from "@solana/web3.js";
import BaseInstruction from "./base";

const CREATOR_PREFIX = 'creator';

export default class InitializeCreatorInstruction extends BaseInstruction {
  static findCreatorDataAccount(programId, donationProtocolDataPubkey, creatorWalletPubkey) {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from(CREATOR_PREFIX),
        donationProtocolDataPubkey.toBuffer(),
        creatorWalletPubkey.toBuffer(),
      ],
      programId,
    );
  }

  async create(
    donationProtocolDataPubkey,
    creatorWalletPubkey,
    payerPubkey,
    donationMintPubKey,
  ) {
    const [creatorDataPubkey] = InitializeCreatorInstruction.findCreatorDataAccount(
      this.program.programId,
      donationProtocolDataPubkey,
      creatorWalletPubkey,
    );

    return this.program.instruction.initializeCreator(
      {
        accounts: {
          creatorData: creatorDataPubkey,
          donationProtocol: donationProtocolDataPubkey,
          creatorWalletAddress: creatorWalletPubkey,
          payer: payerPubkey,
          donationMint: donationMintPubKey,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
      },
    )
  }
}
