function daysToMilliseconds(days) {
  return days * 24 * 60 * 60 * 1000
}

function dateAfterDays(days) {
  const date = new Date()
  return new Date(date.getTime() + daysToMilliseconds(days))
}

function timestampToUTC(timestamp) {
  const date = new Date(timestamp * 1000)
  return date.toUTCString()
}

export { 
  daysToMilliseconds,
  dateAfterDays,
  timestampToUTC,
}
