import {
  Program,
  AnchorProvider,
  BN,
  web3
} from '@coral-xyz/anchor'

import {
  Transaction,
} from '@solana/web3.js'

import {
  createAssociatedTokenAccountInstruction
} from '@solana/spl-token'

import { DONATION_PROTO_ABI } from '../abi/donation_protocol_abi'
import SolanaDonationDataViewer from './solana_donation_data_viewer'
import InitializeCreatorInstruction from './instructions/initialize_creator'
import InitializeContributorInstruction from './instructions/initialize_contributor'
import CreateDonationInstruction from './instructions/create_donation'
import DonateInstruction from './instructions/donate'
import WithdrawFundsInstruction from './instructions/withdraw_funds'
import { getAssociatedTokenAccount } from './spl_helper'


export default class SolanaDonation {
  constructor(wallet, connection, donationProgramId, donationProtocolDataAddress) {
    this.connection = connection
    this.donationProgramId = donationProgramId
    this.donationProtocolDataAddress = donationProtocolDataAddress

    const provider = new AnchorProvider(
      connection, wallet, 'confirmed'
    )
    this.program = new Program(DONATION_PROTO_ABI, this.donationProgramId, provider)
    this.provider = wallet
  }

  async createDonation(
    amount,
    endingTimestamp,
    ipfsHash,
    donationRecipientAddress,
    creatorWalletAddress,
  ) {
    const [creatorDataPubkey] = InitializeCreatorInstruction.findCreatorDataAccount(
      this.program.programId,
      this.donationProtocolDataAddress,
      creatorWalletAddress
    )

    const donationProtocolData = await SolanaDonationDataViewer.checkAndGetDonationProtocolData(this.program, this.donationProtocolDataAddress)

    const instructions = []
    const creatorData = await SolanaDonationDataViewer.getCreatorData(this.program, creatorDataPubkey)
    if (creatorData == null) {
      instructions.push(await (new InitializeCreatorInstruction(this.program)).create(
        this.donationProtocolDataAddress,
        creatorWalletAddress,
        this.provider.publicKey,
        donationProtocolData.donationMint
      ))
    }

    const {
      accountPubkey: recipientTokenAccountPubkey,
      accountInfo: recipientTokenAccountInfo,
    } = await getAssociatedTokenAccount(this.connection, donationProtocolData.donationMint, donationRecipientAddress)
    if (recipientTokenAccountInfo == null) {
      instructions.push(createAssociatedTokenAccountInstruction(
        this.provider.publicKey,
        recipientTokenAccountPubkey,
        donationRecipientAddress,
        donationProtocolData.donationMint,
      ))
    }

    const donationDataKeypair = web3.Keypair.generate()
    instructions.push(...(await (new CreateDonationInstruction(this.program)).create(
      new BN(amount),
      ipfsHash,
      new BN(endingTimestamp),
      donationDataKeypair.publicKey,
      this.donationProtocolDataAddress,
      recipientTokenAccountPubkey,
      creatorDataPubkey,
      donationProtocolData.donationMint,
      creatorWalletAddress
    )))

    const transaction = new Transaction().add(...instructions)
    const signers = [donationDataKeypair]

    console.log(`[!!!] Donation address on-chain: ${donationDataKeypair.publicKey.toString()}`);
    return {
      transaction,
      signers,
    }
  }

  async donate(
    amount,
    donationDataPubkey,
    userWalletAddress,
  ) {
    const donationProtocolData = await SolanaDonationDataViewer.checkAndGetDonationProtocolData(
      this.program,
      this.donationProtocolDataAddress,
    )
    const donationData = await SolanaDonationDataViewer.checkAndGetDonationData(
      this.program,
      donationDataPubkey,
    )
    // validates that donationData is still active
    if (donationData.isClosed === true) {
      throw new Error('Donation is closed')
    }

    const {
      accountPubkey: contributorTokenAccountPubkey,
      accountInfo: contributorTokenAccountInfo,
    } = await getAssociatedTokenAccount(this.connection, donationProtocolData.donationMint, userWalletAddress)
    if (contributorTokenAccountInfo == null) {
      throw new Error('Contributor Token Account not found')
    }
    // TODO: validate that contributor token account has enough balance

    const instructions = []
    const {
      accountPubkey: contributorRewardTokenAccountPubkey,
      accountInfo: contributorRewardTokenAccountInfo,
    } = await getAssociatedTokenAccount(this.connection, donationProtocolData.treasuryMint, userWalletAddress)
    if (contributorRewardTokenAccountInfo == null) {
      instructions.push(createAssociatedTokenAccountInstruction(
        this.provider.publicKey,
        contributorRewardTokenAccountPubkey,
        userWalletAddress,
        donationProtocolData.treasuryMint,
      ))
    }

    const [contributorDataAddress] = InitializeContributorInstruction.findContributorAddress(
      this.program.programId,
      this.donationProtocolDataAddress,
      userWalletAddress,
    )
    const contributorData = await SolanaDonationDataViewer.getContributorData(
      this.program,
      contributorDataAddress,
    )
    if (contributorData == null) {
      instructions.push(await (new InitializeContributorInstruction(this.program)).create(
        this.donationProtocolDataAddress,
        userWalletAddress,
        this.provider.publicKey,
      ))
    }

    const [treasuryOwnerPubkey] = SolanaDonationDataViewer.findTreasuryAddress(
      this.program.programId,
      this.donationProtocolDataAddress,
    )

    instructions.push(await (new DonateInstruction(this.program)).create(
      new BN(amount),
      this.donationProtocolDataAddress,
      userWalletAddress,
      donationDataPubkey,
      contributorTokenAccountPubkey,
      contributorRewardTokenAccountPubkey,
      treasuryOwnerPubkey,
      donationProtocolData.treasury,
      donationData.holdingWallet,
      donationProtocolData.donationMint,
      donationProtocolData.treasuryMint,
    ))

    const transaction = new Transaction().add(...instructions)
    const signers = null

    return {
      transaction,
      signers,
    }
  }

  async withdrawFunds(
    userWalletAddress,
    donationDataPubkey,
  ) {
    const donationData = await SolanaDonationDataViewer.checkAndGetDonationData(
      this.program,
      donationDataPubkey,
    )
    // validates that donationData is still active
    if (donationData.isClosed === true) {
      throw new Error('Donation is closed')
    }
    if (donationData.totalAmountReceived.eqn(0)) {
      throw new Error('No funds to withdraw')
    }

    const [holdingWalletOwnerPubkey] = CreateDonationInstruction.findHoldingWalletAccount(
      this.program.programId,
      donationDataPubkey,
    )

    const donationProtocolData = await SolanaDonationDataViewer.checkAndGetDonationProtocolData(
      this.program,
      this.donationProtocolDataAddress,
    )

    const instructions = []
    const {
      accountPubkey: recipientTokenAccountPubkey,
      accountInfo: recipientTokenAccountInfo,
    } = await getAssociatedTokenAccount(
      this.connection,
      donationProtocolData.donationMint,
      userWalletAddress,
    )
    if (recipientTokenAccountInfo == null) {
      instructions.push(createAssociatedTokenAccountInstruction(
        this.provider.publicKey,
        recipientTokenAccountPubkey,
        userWalletAddress,
        donationProtocolData.donationMint,
      ))
    }

    instructions.push(await (new WithdrawFundsInstruction(this.program)).create(
      this.donationProtocolDataAddress,
      donationDataPubkey,
      donationData.creatorData,
      holdingWalletOwnerPubkey,
      donationData.holdingWallet,
      recipientTokenAccountPubkey,
      donationProtocolData.donationMint,
      userWalletAddress,
    ))

    const transaction = new Transaction().add(...instructions)
    const signers = null

    return {
      transaction,
      signers,
    }
  }
}
