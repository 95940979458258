import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'createDonationButton', 'creatorAddress', 'connectWalletMessage']

  async onWalletConnected() {
    this.createDonationButtonTarget.disabled = false;
    this.connectWalletMessageTarget.hidden = true;
  }
  async onWalletDisconnected() {
    this.createDonationButtonTarget.disabled = true;
    this.connectWalletMessageTarget.hidden = false;
  }
}
