import { PublicKey, SYSVAR_RENT_PUBKEY, SystemProgram } from "@solana/web3.js";
import BaseInstruction from "./base";

const CONTRIBUTOR_PREFIX = 'contributor';

export default class InitializeContributorInstruction extends BaseInstruction  {
  static findContributorAddress(programId, donationProtocolDataPubkey, contributorWalletPubkey) {
    return PublicKey.findProgramAddressSync(
      [
        Buffer.from(CONTRIBUTOR_PREFIX),
        donationProtocolDataPubkey.toBuffer(),
        contributorWalletPubkey.toBuffer(),
      ],
      programId,
    );
  }

  async create(
    donationProtocolDataPubkey,
    contributorWalletPubkey,
    payerPubkey,
  ) {
    const [contributorDataPubkey, contributorDataBump] = InitializeContributorInstruction.findContributorAddress(
      this.program.programId,
      donationProtocolDataPubkey,
      contributorWalletPubkey,
    );

    return this.program.instruction.initializeContributor(
      contributorDataBump,
      {
        accounts: {
          contributorData: contributorDataPubkey,
          donationProtocol: donationProtocolDataPubkey,
          contributorWalletAddress: contributorWalletPubkey,
          payer: payerPubkey,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
        },
      },
    )
  }
}
